import React from "react";
import { connect } from "react-redux";
import { map } from "lodash";
import Card from "../components/Card";
import AppContainer from "../components/AppContainer";
import withTranslation from "../hocs/withTranslation";
import { getStyles } from "../store/selectors";

const TOS = ({ T, appStyles }) => (
  <AppContainer.Content appStyles={appStyles}>
    <Card appStyles={appStyles}>
      <Card.Title appStyles={appStyles}>
        {T("Terms of Service {{business_name}}")}
      </Card.Title>
      <Card.Content appStyles={appStyles}>
        {map(T("{{tos}}").split("\\n"), (line) => (
          <div style={{ marginTop: 15 }}>{line}</div>
        ))}
      </Card.Content>
    </Card>
  </AppContainer.Content>
);

const mapStateToProps = (state, props) => {
  return { appStyles: getStyles(state, props) };
};

export default connect(mapStateToProps)(withTranslation(TOS, "TOS"));
